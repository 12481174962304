<template>
  <div class="readme-article">
    <h1 id="回访跟进">
      二、回访跟进
    </h1>
    <h2 id="预约池">1.预约池</h2>
    <p>招生线索中，有回访预约下次联系的线索会在预约池中显示</p>
    <p>
      <img src="@/assets/img/student/2-3.png">
    </p>
    <h2 id="回访跟踪管理">2.回访跟踪管理</h2>
    <p>回访日志，记录系统所有的回访记录，包含回访人，回访时间，回访内容等信息</p>
    <p>
      <img src="@/assets/img/student/2-1.png">
    </p>
    <h2 id="云呼叫坐席">3.云呼叫坐席</h2>
    <h2 id="电话记录管理">4.电话记录管理</h2>
    <h2 id="主管首页">5.主管首页</h2>
    <p>分组数据总览，可查看业绩，新增线索，回访人数，呼叫时长等信息以及分组成员KPI</p>
    <p>
      <img src="@/assets/img/student/2-2.png">
    </p>
  </div>
</template>

<script>
export default {
  name: "Student2-1",
  data() { 
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>